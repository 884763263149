.App {
  text-align: center;
  background-color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  width: 100%;
  height: 100%;
}

.header, .header > p {
  margin: 0;
  padding-top: 2px;
  padding-left: 10%;
  line-height: 30px;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: white;
}

.resizer-horizontal {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
}

.resizer-vertical {
  position: absolute;
  left: 0;
  bottom: 0;
  vertical-align: bottom;
  height: 5px;
  cursor: row-resize;
  user-select: none;
}

.resizing-horizontal {
  border-right: 2px solid blue;
  z-index: 2;
}

.resizing-vertical {
  border-bottom: 2px solid blue;
  z-index: 2;
}

#spreadsheet {
  position: absolute;
  background-color: white;
}

.AxisX, .AxisY, .origin {
  position: absolute;
  border: 0.8px solid #ACACAC;
  background-color:#EFEFEF;
  color: #5F5F5F;
  text-align: center;
}

.entryCell {
  position: absolute;
  border: 0.5px solid #D5D5D5;
  background-color:white;
  text-align: center;
}

.navButton {
  border-width: 0; 
  background-color: black;
  color: white; 
  text-decoration: none;
  padding: 1vw; 
  transition: background-color .3s, color .3s; 
  transition-timing-function: ease-out;
}

.navButton:hover {
  background-color: white; 
  color: black;
}

.managerNavBar {
  display: flex; 
  flex-direction: row; 
  justify-content: flex-end;
  padding: .5%; 
  background: rgba(20, 30, 40, 1);
}

.sheetPreview {
  background-color: white;
}

.editorNavBar {
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  padding: .5%; 
  background: rgba(20, 30, 40, 1);
}